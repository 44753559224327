<template>
  <ion-header translucent>
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-back-button text="" default-href="/" />
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content>
    <blank-slate
      icon="offline"
      title="Not available offline"
      description="Try again when you are online and it will be available offline next time."
    />
  </ion-content>
</template>
